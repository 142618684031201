import styled from 'styled-components';

export const Header2 = styled.h2`
  text-align: center;
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #00539d;
  @media (max-width: 1439px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;

export const ImageStyle = styled.img`
  border: 0;
  vertical-align: top;
  margin: 0 0 35px 0;
  height: 100px;
  width: auto;
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  color: #00a6e8;
  transition: 0.25s ease-out;
  text-align: center;
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 45px;
  line-height: 50px;

  @media (max-width: 1439px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
